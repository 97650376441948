/* reset css */
a:focus {
  outline: none;
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body,
html {
  min-height: 100%;
}

html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: 100%;
  font-weight: normal;
}

fieldset,
img,
abbr,
acronym {
  border: 0 none;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  border: 0 none;
}

caption,
th,
td {
  text-align: left;
  font-weight: normal;
  border: 0;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
  display: inline;
  zoom: 1;
}

img,
video {
  display: block;
  width: 100%;
  height: 100%;
  border: 0;
  object-fit: cover;
  image-rendering: -moz-auto;
  /*firefox*/
  image-rendering: -o-auto;
  /*opera*/
  image-rendering: -webkit-optimize-contrast;
  /*webkit*/
  image-rendering: auto;
  -ms-interpolation-mode: nearest-neighbor;
  /* -webkit-perspective: 1; */
}

ul,
ol,
dl,
li {
  list-style: none;
}

a {
  display: block;
  text-decoration: none;
  color: inherit;
}

i {
  font-style: normal;
  font-weight: normal;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input,
select,
label {
  vertical-align: middle;
}

button {
  cursor: pointer;
}

button,
input,
select,
textarea {
  margin: 0;
  font-weight: normal;
  letter-spacing: normal;
  background: none;
}

button,
input {
  line-height: normal;
  box-shadow: none;
}

input {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

input::-ms-clear,
input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input::-webkit-search-decoration,
input::-webkit-search-cancel-button,
input::-webkit-search-results-button,
input::-webkit-search-results-decoration {
  display: none;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

button,
select {
  text-transform: none;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  resize: none;
}

address {
  font-style: normal;
}

/* ::-webkit-scrollbar { width: 6px; height: 6px; } 
  ::-webkit-scrollbar-track { background: transparent; } 
  ::-webkit-scrollbar-thumb { background: #888; border-radius: 999px; background-clip: padding-box; border-top: 7px solid transparent; border-bottom: 10px solid transparent; border-right: 2px solid transparent; border-left: 2px solid transparent; } */

html {
  -webkit-text-size-adjust: none;
  -moz-text-size-adjust: none;
  -ms-text-size-adjust: none;
  -o-text-size-adjust: none;
}

input::-webkit-date-and-time-value {
  margin-top: 0px;
  text-align: left;
  width: 100%;
}

/* common style */
@import url("https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@100;200;300;400;500;600;700;800;900&display=swap");

:root {
  --black-color-01: #3e4450;
  --black-color-02: #303541;
  --gray-color-01: #767a82;
  --gray-color-02: #989fad;
  --gray-color-03: #abb2be;
  --gray-color-04: #ecf0f8;
  --gray-color-05: #f7f8fa;
  --main-color: #60b4f3;
  --red-color: #ff4040;
}

body {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  color: var(--black-color-01);
  letter-spacing: -0.02em;
  -webkit-font-smoothing: antialiased;
  scroll-behavior: smooth;
}

html.hidden {
  overflow: hidden;
}
body.hidden {
  height: auto;
  overflow: hidden;
}

button,
input,
textarea,
select {
  font-family: "Noto Sans KR", sans-serif;
  font-size: 14px;
  color: var(--black-color-01);
  letter-spacing: -0.02em;
}

input::placeholder {
  color: var(--gray-color-03);
}

.mbOnly {
  display: none;
}

.inner {
  width: 1540px;
  margin: 0 auto;
}
.middleInner {
  width: 1414px;
  margin: 0 auto;
}
.narrowInner {
  width: 500px;
  margin: 0 auto;
}

@media (max-width: 1600px) {
  body {
    font-size: calc(100vw * (14 / 1600));
    -webkit-tap-highlight-color : transparent;
  }
  button,
  input,
  textarea,
  select {
    font-size: calc(100vw * (14 / 1600));
  }
  .inner {
    width: calc(100vw * (1540 / 1600));
  }
  .middleInner {
    width: calc(100vw * (1414 / 1600));
  }
  .narrowInner {
    width: calc(100vw * (500 / 1600));
  }
}

@media (max-width: 768px) {
  body {
    font-size: calc(100vw * (14 / 370));
    -webkit-tap-highlight-color : transparent;
  }
  button,
  input,
  textarea,
  select {
    font-size: calc(100vw * (14 / 370));
  }
  .inner {
    width: calc(100vw * (320 / 370));
  }
  .middleInner {
    width: calc(100vw * (320 / 370));
  }
  .narrowInner {
    width: calc(100vw * (320 / 370));
  }
  .pcOnly {
    display: none;
  }

  .mbOnly {
    display: block;
  }
}
